import http from "../http-common";
import {
  handleDocumentRequest,
  getDocumentSuccess,
  getAllAssignedDocumentSuccess,
  getRecordDocumentSuccess,
  getComplainceDocumentSuccess,
  getDirectorDocumentSuccess,
  getAssignedDocumentsSuccess,
  getUnassignedDocumentSuccess,
  getApprovedDocumentSuccess,
  getRejectedDocumentSuccess,
  getDocumentCountsSuccess,
  getDocumentsChartSuccess,
  handleDocumentError,
  createDocumentSuccess,
  updateDocumentSuccess,
  updateDocumentState,
  updateUnAssignedDocumentState,
  addDocumentLetterSuccess,
} from "../redux/Slices/DocumentSlice";
import { toast } from "react-toastify";
import {
  addCommentToLetterSuccess,
  addDocumentRecordsSuccess,
  addLetterSuccess,
  getSingleDocumentSuccess,
  handleSingleDocumentRequest,
  updateLetterSuccess,
} from "../redux/Slices/SingleDocumentSlice";
import { addBusinessMessageSuccess } from "../redux/Slices/BusinessRegistrationSlice";

export const getAllDocuments = (dispatch) => {
  dispatch(handleDocumentRequest());

  http
    .get(`/records/recordDocument/`)
    .then((response) => {
      dispatch(getDocumentSuccess(response.data.data));
    })
    .catch((error) => {
      dispatch(handleDocumentError());
      toast.error(error.response.data.message);
    });
};

export const getAllDocumentStatusDispatch = (dispatch) => {
  dispatch(handleDocumentRequest());

  http
    .get(`document/getAllDocumentStatusDispatch`)
    .then((response) => {
      dispatch(getDocumentSuccess(response.data.data));
    })
    .catch((error) => {
      dispatch(handleDocumentError());
      toast.error(error.response.data.message);
    });
};

export const updateDispatchDocumentStatus = (
  data,
  dispatch,
  document_id,
  setLoading
) => {
  dispatch(handleDocumentRequest);
  http
    .put(`/document/updateDispatchValue/${document_id}`, data)
    .then((response) => {
      window.location.reload();
      setLoading(false);
    })
    .catch((error) => {
      toast.error("Error dispatching document assignment");
      setLoading(false);

      console.log(error);
    });
};

export const getAllAssignedDocuments = (dispatch) => {
  dispatch(handleDocumentRequest());

  http
    .get(`/common/assignedDocuments/`)
    .then((response) => {
      dispatch(getAllAssignedDocumentSuccess(response.data.data));
    })
    .catch((error) => {
      dispatch(handleDocumentError());
      toast.error(error.response.data.message);
    });
};

export const getRecordDocuments = (dispatch) => {
  dispatch(handleDocumentRequest());

  http
    .get(`/records/recordDocument/`)
    .then((response) => {
      dispatch(getRecordDocumentSuccess(response.data.data));
    })
    .catch((error) => {
      dispatch(handleDocumentError());
      toast.error(error.response.data.message);
    });
};

export const addRecordDocument = (data, dispatch, history) => {
  // dispatch(handleDocumentRequest);
  http
    .post("/records/", data)
    .then((response) => {
      toast.success("Record document added successfully");
      history.push("/manage-documents");
      dispatch(addDocumentRecordsSuccess(response.data.data));
    })
    .catch((error) => {
      toast.error("Error adding record");
    });
};

export const updateAssignedDocumentsDocument = (
  data,
  dispatch,
  document_id
) => {
  dispatch(handleDocumentRequest);
  http
    .post(`/seniorManager/unassignDoc/${document_id}`, data)
    .then((response) => {
      toast.success("Unassigned successfully");
      window.location.reload();
    })
    .catch((error) => {
      toast.error("Error removing assignment");
      console.log(error);
    });
};

export const updateRecordDocument = (data, dispatch, history, record_id) => {
  dispatch(handleDocumentRequest);
  http
    .put(`/records/${record_id}`, data)
    .then((response) => {
      toast.success(response.data.message);
      // history.push(`/view-document/${data.documentId}`);
      // window.location.reload();
    })
    .catch((error) => {
      console.log(error);
      toast.error(error.response.data.message);
      toast.error("Error updating document");
    });
};

export const getSingleRecordDocument = async (record_id) => {
  const data = await http
    .get(`/records/${record_id}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response;
    });

  return data;
};

export const getSingleDocument = async (document_id) => {
  const data = await http
    .get(`/document/${document_id}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response;
    });

  return data;
};

export const getSinglePDFDocument = async (document_id) => {
  const data = http
    .get(`/document/readDocs/subDocs/${document_id}`, {
      responseType: "arraybuffer",
    })
    .then((response) => {
      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      return url;
    })
    .catch((error) => {
      // toast.error("No pdf document");
    });

  return data;
};

export const getComplianceDocuments = (dispatch) => {
  dispatch(handleDocumentRequest());

  http
    .get(`/compliance/complianceDocument/`)
    .then((response) => {
      dispatch(getComplainceDocumentSuccess(response.data.data));
    })
    .catch((error) => {
      dispatch(handleDocumentError());
      toast.error(error.response.data.message);
    });
};

export const getDirectorDocuments = (dispatch) => {
  dispatch(handleDocumentRequest());

  http
    .get(`/document/documentRecordEntry/`)
    .then((response) => {
      dispatch(getDirectorDocumentSuccess(response.data.data));
    })
    .catch((error) => {
      dispatch(handleDocumentError());
      toast.error(error.response.data.message);
    });
};

export const getAssignedDocuments = (dispatch) => {
  dispatch(handleDocumentRequest());

  http
    .get("/document/seniorAndComplianceDocs/")
    .then((response) => {
      dispatch(getAssignedDocumentsSuccess(response.data.data));
    })
    .catch((error) => {
      dispatch(handleDocumentError());
      toast.error(error.response.data.message);
    });
};

export const getDocumentChartsData = (dispatch) => {
  dispatch(handleDocumentRequest());

  http
    .get("/document/documentBaseOnDepartment/")
    .then((response) => {
      dispatch(getDocumentsChartSuccess(response.data));
    })
    .catch((error) => {
      dispatch(handleDocumentError());
      toast.error(error.response.data.message);
    });
};

export const getComplianceAssignedDocuments = (dispatch) => {
  dispatch(handleDocumentRequest());

  http
    .get("/compliance/complianceDocument/")
    .then((response) => {
      dispatch(getAssignedDocumentsSuccess(response.data.data));
    })
    .catch((error) => {
      dispatch(handleDocumentError());
      toast.error(error.response.data.message);
    });
};

export const getUnassignedDocuments = (dispatch) => {
  dispatch(handleDocumentRequest());

  http
    .get("/common/unAssignDocuments/")
    .then((response) => {
      dispatch(getUnassignedDocumentSuccess(response.data.data));
    })
    .catch((error) => {
      dispatch(handleDocumentError());
      toast.error(error.response.data.message);
    });
};

export const getApprovedDocuments = (dispatch) => {
  dispatch(handleDocumentRequest());

  http
    .get("/document/getApprovedDocuments/")
    .then((response) => {
      dispatch(getApprovedDocumentSuccess(response.data.data));
    })
    .catch((error) => {
      dispatch(handleDocumentError());
      toast.error(error.response.data.message);
    });
};

export const getRejectedDocuments = (dispatch) => {
  dispatch(handleDocumentRequest());

  http
    .get("/document/rejectedDocuments/")
    .then((response) => {
      dispatch(getRejectedDocumentSuccess(response.data.data));
    })
    .catch((error) => {
      dispatch(handleDocumentError());
      toast.error(error.response.data.message);
    });
};

export const updateAssignedDocuments = (
  data,
  dispatch,
  document_id,
  history
) => {
  dispatch(handleDocumentRequest());
  http
    .post(`/seniorManager/addUserDocuments/${document_id}`, data)
    .then((response) => {
      toast.success(response.data.message);
      dispatch(createDocumentSuccess());
      dispatch(updateUnAssignedDocumentState(document_id));
      dispatch(updateDocumentState(document_id));
      // window.location.reload();
      // history.push("/assigned-documents");
    })
    .catch((error) => {
      console.log(error);
      dispatch(handleDocumentError());
      toast.error(error.response.data.message);
      console.log(error.message);
    });
};

export const updateAssignedFromSeniorManagement = (
  data,
  dispatch,
  history,
  document_id
) => {
  dispatch(handleDocumentRequest());

  http
    .put(`/seniorManager/seniorAssignDocument/${document_id}`, data)
    .then((response) => {
      toast.success(response.data.message);
      dispatch(createDocumentSuccess());
      window.location.reload();
    })
    .catch((error) => {
      dispatch(handleDocumentError());
      toast.error(error.response.data.message);
    });
};

export const updateAssignedFromCompliance = (
  dispatch,
  document_id,
  history
) => {
  dispatch(handleDocumentRequest());
  http
    .put(`/compliance/complianceAssignDocument/${document_id}`)
    .then((response) => {
      toast.success(response.data.message);
      // window.location.reload();
      dispatch(updateDocumentSuccess());
      dispatch(updateDocumentState(document_id));
      history.push("/assigned-documents");
    })
    .catch((error) => {
      dispatch(handleDocumentError());
      toast.error(error.response.data.message);
    });
};

export const getAllDocumentsCount = (dispatch, year) => {
  dispatch(handleDocumentRequest());

  http
    .get("/document/getDocumentCounts/", {
      params: {
        year
      }
    })
    .then((response) => {
      dispatch(getDocumentCountsSuccess(response.data));
    })
    .catch((error) => {
      dispatch(handleDocumentError());
      toast.error(error.response.message);
    });
};

export const approvedOrRejectDirector = (
  dispatch,
  document_id,
  comment,
  setReload
) => {
  dispatch(handleDocumentRequest());
  http
    .put(`/director/approveOrRejectDocDirector/${document_id}`, comment)
    .then((response) => {
      toast.success(response.data.message);
      setReload(true);
      // window.location.reload();
    })
    .catch((error) => {
      toast.error(error.response.data.message);
    });
};

export const approvedOrRejectSeniorManagement = (
  dispatch,
  document_id,
  comment,
  setReload
) => {
  dispatch(handleDocumentRequest());
  http
    .put(
      `/seniorManager/approveOrRejectDocSeniorManagement/${document_id}`,
      comment
    )
    .then((response) => {
      toast.success(response.data.message);
      // window.location.reload();
      setReload(true);
    })
    .catch((error) => {
      toast.error(error.response.data.message);
    });
};

export const seniorMnagerAssignBack = (document_id, history) => {
  http
    .put(`/seniorManager/seniorAssignDocumentBack/${document_id}`)
    .then((response) => {
      toast.success(response.data.message);
      // window.location.reload();
      history.push("/assigned-documents");
    })
    .catch((error) => {
      toast.error(error.response.data.message);
    });
};

export const getAllComplainceUsers = async (setComplianceUsers) => {
  await http
    .get("/complianceUser")
    .then((response) => {
      // toast.success(response.data.message);
      setComplianceUsers(response.data.data);
    })
    .catch((error) => {
      toast.error(error.response.data.message);
    });
};

export const getDocumentComment = async (id) => {
  try {
    const response = await http.get(`comment/${id}`);
    return response.data.data;
  } catch (error) {
    toast.error(error.data.message);
  }
};

export const addLetterToDocument = async (data, dispatch) => {
  try {
    const response = await http.post("/letter/", data);
    dispatch(addLetterSuccess(response.data.data));
    toast.success("Letter created successfully");
  } catch (error) {
    toast.error("Error creating letter");
    console.log(error);
  }
};

export const addBusinessRegistrationMessage = async (data, dispatch) => {
  console.log(dispatch);
  try {
    const response = await http.post("/businessMessage/", data);
    dispatch(addBusinessMessageSuccess(response.data.data));
    // console.log("api responseeee", response);
    // toast.success(response.data.message);
  } catch (error) {
    toast.error("Error creating letter");
    console.log(error);
  }
};

export const addCommentToDocument = async (data, dispatch) => {
  try {
    const response = await http.post("/comment/", data);
    dispatch(addCommentToLetterSuccess(response.data.data));
    toast.success("Comment created successfully");
  } catch (error) {
    toast.error(error);
    console.error(error);
  }
};

export const updateDocumentLetter = async (data, dispatch, letter_id) => {
  try {
    const response = await http.put(`letter/${letter_id}`, data);
    toast.success(response.data.message);
    dispatch(updateLetterSuccess(response.data.data));
  } catch (error) {
    toast.error(error.data.message);
  }
};

export const getDocumentFolder = async (document_id, dispatch) => {
  dispatch(handleSingleDocumentRequest());
  try {
    const response = await http.get(
      `/projectDocumentFolder/getSingleProjectDocumentFolderForAdmin/${document_id}`
    );
    const documentData = response.data.data;
    dispatch(getSingleDocumentSuccess(documentData));
    return documentData;
  } catch (error) {
    toast.error(error?.data?.message);
  }
};
