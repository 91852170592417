import React, { useContext, useState } from "react";

/// React router dom
import { Switch, Route } from "react-router-dom";
import { ThemeContext } from "../context/ThemeContext";
import { InstitutionFolder } from "./components/Documents/AllDocuments/InstitutionFolder";
import { getInstitutionFile } from "../services/FolderService";
import { InsitutionFiles } from "./components/Documents/AllDocuments/InstitutionFile";
import { EditBusiness } from "./components/BusinessLicensing/EditBusiness";
import { ReadyForDispatch } from "./components/Documents/ReadyForDispatch";
/// Css
import "./index.css";
import "./chart.css";
import "./step.css";
import { lazy } from "react";
import { Suspense } from "react";
import LoadingSpinner from "./components/Backdrop/LoadingSpinner";
import ViewFiles from "./components/Documents/ViewFiles";

/// Layout
const Nav = lazy(() => import("./layouts/nav/admin"));
const Footer = lazy(() => import("./layouts/Footer"));
const ScrollToTop = lazy(() => import("./layouts/ScrollToTop"));
/// Dashboard
const Home = lazy(() => import("./components/Dashboard/Home"));
const DashboardDark = lazy(() =>
  import("./components/Dashboard/DashboardDark")
);
const GuestList = lazy(() => import("./components/Dashboard/GuestList"));
const GuestDetails = lazy(() => import("./components/Dashboard/GuestDetails"));
const ConciergeList = lazy(() =>
  import("./components/Dashboard/ConciergeList")
);
const RoomList = lazy(() => import("./components/Dashboard/RoomList"));
const Reviews = lazy(() => import("./components/Dashboard/Reviews"));
const Task = lazy(() => import("./components/Dashboard/Task"));

// User Management
const AddUser = lazy(() => import("./components/UserManagement/AddUser"));
const ManageUsers = lazy(() =>
  import("./components/UserManagement/ManageUsers")
);
const UpdateUser = lazy(() => import("./components/UserManagement/UpdateUser"));
const ManageRoles = lazy(() =>
  import("./components/UserManagement/ManageRoles")
);
const ManageRolePermissions = lazy(() =>
  import("./components/UserManagement/ManageRolePermissions")
);
const AddRole = lazy(() => import("./components/UserManagement/AddRole"));

// Client Management
const AddClient = lazy(() => import("./components/ClientManagement/AddClient"));
const UpdateClient = lazy(() =>
  import("./components/ClientManagement/UpdateClient")
);
const ManageClient = lazy(() =>
  import("./components/ClientManagement/ManageClient")
);

// Procurement methods
const AddProcurement = lazy(() =>
  import("./components/ProcurementMethod/AddProcurement")
);
const ManageProcurement = lazy(() =>
  import("./components/ProcurementMethod/ManageProcurement")
);

// Institution Management
const ManageInstitution = lazy(() =>
  import("./components/Institution/ManageInstitution")
);
const AddInstitution = lazy(() =>
  import("./components/Institution/AddInstitution")
);
const UpdateInstitution = lazy(() =>
  import("./components/Institution/UpdateInstitution")
);

// Document Type Management
const ManageDocumentType = lazy(() =>
  import("./components/DocumentType/ManageDocumentType")
);
const AddDocumentType = lazy(() =>
  import("./components/DocumentType/AddDocumentType")
);
const UpdateDocumentType = lazy(() =>
  import("./components/DocumentType/UpdateDocumentType")
);

// Documents
const ViewDocument = lazy(() => import("./components/Documents/ViewDocument"));

const ViewDocumentDuplicate = lazy(() =>
  import("./components/Documents/ViewDocumentDuplicate")
);
const ViewComplianceDocument = lazy(() =>
  import("./components/Documents/ViewComplianceDocument")
);
const ApprovedDocuments = lazy(() =>
  import("./components/Documents/ApprovedDocuments")
);
const RejectedDocuments = lazy(() =>
  import("./components/Documents/RejectedDocuments")
);
const UnassignedDocuments = lazy(() =>
  import("./components/Documents/UnassignedDocuments")
);
const AssignedDocuments = lazy(() =>
  import("./components/Documents/AssignedDocuments")
);
const AllAssignedDocuments = lazy(() =>
  import("./components/Documents/AllAssignedDocuments")
);
const ManageDocuments = lazy(() =>
  import("./components/Documents/ManageDocuments")
);
const SearchDocument = lazy(() =>
  import("./components/Documents/SearchDocument")
);
const AllDocumentsCopy = lazy(() =>
  import("./components/Documents/AllDocuments/AllDocuments")
);
// Security
const Security = lazy(() => import("./components/Security/Security"));
const Validate2FA = lazy(() => import("./components/Security/Validate2FA"));
const VerificationSuccess = lazy(() =>
  import("./components/Security/VerificationSucess")
);
const CheckAuthorization = lazy(() =>
  import("./components/Security/CheckAuthorization")
);
const ChangePassword = lazy(() =>
  import("./components/changePassword/ChangePassword")
);

// Logs
const Logs = lazy(() => import("./components/Logs/Logs"));

/// Chat
const Chat = lazy(() => import("./components/Chat/Chat"));

///Business Licensing
const RegisterBusinessClient = lazy(() => import("./components/BusinessLicensing/RegisterBusinessClient"));
const EditBusinessClient = lazy(() => import("./components/BusinessLicensing/EditBusinessClient"))
const ManageBusiness = lazy(() => import("./components/BusinessLicensing/ManageBusiness"));
const BusinessRegistration = lazy(() => import("./components/BusinessLicensing/BusinessRegistration"));
const ViewBusiness = lazy(() => import("./components/BusinessLicensing/ViewBusiness"));
const AllBusinesses = lazy(() => import("./components/BusinessLicensing/AllBusinesses"));
const CreateNewBusiness = lazy(() => import("./components/BusinessLicensing/CreateNewBusiness"));
const ViewSingleBusiness = lazy(() => import("./components/BusinessLicensing/ViewSingleBusiness"));
const EditBusinessRequests = lazy(() => import("./components/BusinessLicensing/EditBusinessRequests/index"))

// HR
const Employees = lazy(() => import("./components/HR/Employees"));
const EmployeesSalary = lazy(() => import("./components/HR/EmployeesSalary"));
const ViewLeaveApplication = lazy(() =>
  import("./components/HR/ViewLeaveApplication")
);
const ApplyForLeave = lazy(() => import("./components/HR/ApplyForLeave"));

//Tariffs
const ManageTariff = lazy(() =>
  import("./components/TariffManangement/ManageTarrif")
);
const AddTariff = lazy(() =>
  import("./components/TariffManangement/AddTarriff")
);

/////Demo
const Theme1 = lazy(() => import("./components/Dashboard/Demo/Theme1"));
const Theme2 = lazy(() => import("./components/Dashboard/Demo/Theme2"));
const Theme3 = lazy(() => import("./components/Dashboard/Demo/Theme3"));
const Theme4 = lazy(() => import("./components/Dashboard/Demo/Theme4"));
const Theme5 = lazy(() => import("./components/Dashboard/Demo/Theme5"));
const Theme6 = lazy(() => import("./components/Dashboard/Demo/Theme6"));

/// App
const AppProfile = lazy(() =>
  import("./components/AppsMenu/AppProfile/AppProfile")
);
const Compose = lazy(() =>
  import("./components/AppsMenu/Email/Compose/Compose")
);
const Inbox = lazy(() => import("./components/AppsMenu/Email/Inbox/Inbox"));
const Read = lazy(() => import("./components/AppsMenu/Email/Read/Read"));
const Calendar = lazy(() => import("./components/AppsMenu/Calendar/Calendar"));
const PostDetails = lazy(() =>
  import("./components/AppsMenu/AppProfile/PostDetails")
);
const UpdateProfile = lazy(() =>
  import("./components/AppsMenu/AppProfile/UpdateProfile")
);

/// Product List
const ProductGrid = lazy(() =>
  import("./components/AppsMenu/Shop/ProductGrid/ProductGrid")
);
const ProductList = lazy(() =>
  import("./components/AppsMenu/Shop/ProductList/ProductList")
);
const ProductDetail = lazy(() =>
  import("./components/AppsMenu/Shop/ProductGrid/ProductDetail")
);
const Checkout = lazy(() =>
  import("./components/AppsMenu/Shop/Checkout/Checkout")
);
const Invoice = lazy(() =>
  import("./components/AppsMenu/Shop/Invoice/Invoice")
);
const ProductOrder = lazy(() =>
  import("./components/AppsMenu/Shop/ProductOrder")
);
const Customers = lazy(() =>
  import("./components/AppsMenu/Shop/Customers/Customers")
);

/// Charts
const SparklineChart = lazy(() => import("./components/charts/Sparkline"));
const ChartJs = lazy(() => import("./components/charts/Chartjs"));
const Chartist = lazy(() => import("./components/charts/chartist"));
const RechartJs = lazy(() => import("./components/charts/rechart"));
const ApexChart = lazy(() => import("./components/charts/apexcharts"));

/// Bootstrap
const UiAlert = lazy(() => import("./components/bootstrap/Alert"));
const UiAccordion = lazy(() => import("./components/bootstrap/Accordion"));
const UiBadge = lazy(() => import("./components/bootstrap/Badge"));
const UiButton = lazy(() => import("./components/bootstrap/Button"));
const UiModal = lazy(() => import("./components/bootstrap/Modal"));
const UiButtonGroup = lazy(() => import("./components/bootstrap/ButtonGroup"));
const UiListGroup = lazy(() => import("./components/bootstrap/ListGroup"));
const UiCards = lazy(() => import("./components/bootstrap/Cards"));
const UiCarousel = lazy(() => import("./components/bootstrap/Carousel"));
const UiDropDown = lazy(() => import("./components/bootstrap/DropDown"));
const UiPopOver = lazy(() => import("./components/bootstrap/PopOver"));
const UiProgressBar = lazy(() => import("./components/bootstrap/ProgressBar"));
const UiTab = lazy(() => import("./components/bootstrap/Tab"));
const UiPagination = lazy(() => import("./components/bootstrap/Pagination"));
const UiGrid = lazy(() => import("./components/bootstrap/Grid"));
const UiTypography = lazy(() => import("./components/bootstrap/Typography"));

// Signature
const Signature = lazy(() => import("./components/Signature/Signature"));

/// Plugins
const Select2 = lazy(() => import("./components/PluginsMenu/Select2/Select2"));
//const Nestable = lazy(() => import("./components/PluginsMenu/Nestable/Nestable"));
const MainNouiSlider = lazy(() =>
  import("./components/PluginsMenu/NouiSlider/MainNouiSlider")
);
const MainSweetAlert = lazy(() =>
  import("./components/PluginsMenu/SweetAlert/SweetAlert")
);
const Toastr = lazy(() => import("./components/PluginsMenu/Toastr/Toastr"));
const JqvMap = lazy(() => import("./components/PluginsMenu/JqvMap/JqvMap"));
const Lightgallery = lazy(() =>
  import("./components/PluginsMenu/Lightgallery/Lightgallery")
);

//Redux
const Todo = lazy(() => import("./pages/Todo"));
//const ReduxForm = lazy(() => import("./components/Forms/ReduxForm/ReduxForm"));
const WizardForm = lazy(() => import("./components/Forms/ReduxWizard/Index"));

/// Widget
const Widget = lazy(() => import("./pages/Widget"));

/// Table
const SortingTable = lazy(() =>
  import("./components/table/SortingTable/SortingTable")
);
const FilteringTable = lazy(() =>
  import("./components/table/FilteringTable/FilteringTable")
);
const DataTable = lazy(() => import("./components/table/DataTable"));
const BootstrapTable = lazy(() => import("./components/table/BootstrapTable"));

/// Form
const Element = lazy(() => import("./components/Forms/Element/Element"));
const Wizard = lazy(() => import("./components/Forms/Wizard/Wizard"));
const SummerNote = lazy(() =>
  import("./components/Forms/Summernote/SummerNote")
);
const Pickers = lazy(() => import("./components/Forms/Pickers/Pickers"));
const jQueryValidation = lazy(() =>
  import("./components/Forms/jQueryValidation/jQueryValidation")
);

/// Pages
const Registration = lazy(() => import("./pages/Registration"));
const Login = lazy(() => import("./pages/Login"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const LockScreen = lazy(() => import("./pages/LockScreen"));
const Error400 = lazy(() => import("./pages/Error400"));
const Error403 = lazy(() => import("./pages/Error403"));
const Error404 = lazy(() => import("./pages/Error404"));
const Error500 = lazy(() => import("./pages/Error500"));
const Error503 = lazy(() => import("./pages/Error503"));
const Setting = lazy(() => import("./layouts/Setting"));
const AddLeaseFromUnit = lazy(() =>
  import("./components/LeasesTenancy/AddLeaseFromUnit")
);
const ChatBox = lazy(() => import("./layouts/ChatBox"));
const Certificate = lazy(() => import("./components/Certificate/Certificate"));
const AuthorizeBusiness = lazy(() =>
  import("./components/BusinessLicensing/AuthorizeBusiness")
);
const ViewLetter = lazy(() => import("./components/Letter/ViewLetter"));
const Landingpage = lazy(() =>
  import("./components/BusinessLicensing/Landingpage")
);
const LandingPage2 = lazy(() => import("./pages/LandingPage2"));
const Folder = lazy(() => import("./components/Documents/AllDocuments/Folder"));
const AllDocuments = lazy(() => import("./components/Documents/AllDocuments"));
const UpdateProcurement = lazy(() =>
  import("./components/ProcurementMethod/UpdateProcurement")
);
const BusinessReports = lazy(() =>
  import("./components/AdminReports/BusinessReports")
);

const PendingCertificates = lazy(() =>
  import("./components/BusinessLicensing/PendingCertificates")
);

//reports
// const BusinessReports = lazy(() => import("./components/AdminReports/BusinessReports"));
// const UpdateProcurement = lazy(() => import("./components/ProcurementMethod/UpdateProcurement"));

const Markup = () => {
  const { menuToggle } = useContext(ThemeContext);

  const routes = [
    /// Dashboard
    { url: "", component: Home },
    { url: "dashboard-dark", component: DashboardDark },
    { url: "guest-list", component: GuestList },
    { url: "guest-details", component: GuestDetails },
    { url: "concierge-list", component: ConciergeList },
    { url: "room-list", component: RoomList },
    { url: "reviews", component: Reviews },
    { url: "task", component: Task },
    { url: "certificate/:id", component: Certificate },

    //Business reports
    { url: "business-reports", component: BusinessReports },

    // User Management
    { url: "add-user", component: AddUser },
    { url: "manage-users", component: ManageUsers },
    { url: "update-user/:user_id", component: UpdateUser },
    { url: "manage-roles", component: ManageRoles },
    { url: "manage-roles-permission", component: ManageRolePermissions },
    { url: "add-roles-permission", component: AddRole },

    // Client Management
    { url: "add-client", component: AddClient },
    { url: "update-client/:client_id", component: UpdateClient },
    { url: "manage-clients", component: ManageClient },

    { url: "add-procurement", component: AddProcurement },
    { url: "manage-procurement", component: ManageProcurement },
    { url: "edit-procurement/:id", component: UpdateProcurement },

    { url: "manage-institutions", component: ManageInstitution },
    { url: "add-institution", component: AddInstitution },
    { url: "edit-institution/:institution_id", component: UpdateInstitution },

    { url: "manage-document-type", component: ManageDocumentType },
    { url: "add-document-type", component: AddDocumentType },
    {
      url: "edit-document-type/:document_type_id",
      component: UpdateDocumentType,
    },

    //  Documents
    { url: "all-documents", component: AllDocuments },
    { url: "all-documents-copy", component: AllDocumentsCopy },
    { url: "all-documents-copy:/slug", component: Folder },
    { url: "manage-documents", component: ManageDocuments },
    { url: "view-document-details/:document_id", component: ViewDocument },
    { url: "view-document/:document_id", component: ViewDocumentDuplicate },

    { url: "institution-folders/:id", component: InstitutionFolder },
    { url: "institution-files/:id", component: InsitutionFiles },
    { url: "view-file/:id/:fileName", component: ViewFiles },

    {
      url: "view-compliance-document/:document_id/:letter_id",
      component: ViewComplianceDocument,
    },
    { url: "ready-for-dispatch", component: ReadyForDispatch },
    { url: "approved-documents", component: ApprovedDocuments },
    { url: "rejected-documents", component: RejectedDocuments },
    { url: "assigned-documents", component: AssignedDocuments },
    { url: "unassigned-documents", component: UnassignedDocuments },
    { url: "all-assigned-documents", component: AllAssignedDocuments },
    { url: "search-document", component: SearchDocument },

    // Landing Page
    { url: "landing-page", component: LandingPage2 },

    // Logs
    { url: "logs", component: Logs },

    { url: "chat", component: Chat },

    { url: "security", component: Security },

    { url: "change-password", component: ChangePassword },

    { url: "validate-2fa", component: Validate2FA },

    { url: "verification-success", component: VerificationSuccess },

    // Business Licensing
    { url: "register-business-client", component: RegisterBusinessClient },
    {url: "edit-business-client/:clientId", component: EditBusinessClient},
    { url: "create-business/:business_id", component: CreateNewBusiness },
    { url: "manage-business", component: ManageBusiness },
    { url: "edit-business/:business_id", component: EditBusiness },
    { url: "view-business/:id", component: ViewBusiness },
    { url: "business-registration", component: BusinessRegistration },
    { url: "business/:id", component: AllBusinesses },
    { url: "pending-certificates", component: PendingCertificates },

    { url: "authorize-business", component: AuthorizeBusiness },
    { url: "business-registration-home", component: Landingpage },
    { url: "edit-business-requests", component: EditBusinessRequests },

    //Manage Tariff

    { url: "manage-tariffs", component: ManageTariff },
    { url: "add-tariff", component: AddTariff },

    //TODO
    //Create route view-single-business
    { url: "view-single-business", component: ViewSingleBusiness },

    //create component for this route and attach

    // HR
    { url: "employees", component: Employees },
    { url: "employees-salary", component: EmployeesSalary },
    { url: "view-leave-application", component: ViewLeaveApplication },
    { url: "apply-for-leave", component: ApplyForLeave },
    // { url: 'check-authorization', component: CheckAuthorization},

    // Signature
    { url: "signature", component: Signature },

    ///Demo
    { url: "primary-sidebar", component: Theme1 },
    { url: "mini-primary-sidebar", component: Theme2 },
    { url: "compact-primary-header", component: Theme3 },
    { url: "horizontal-primary-sidebar", component: Theme4 },
    { url: "horizontal-modern-sidebar", component: Theme5 },
    { url: "modern-sidebar", component: Theme6 },

    /// Apps
    { url: "app-profile", component: AppProfile },
    { url: "email-compose", component: Compose },
    { url: "email-inbox", component: Inbox },
    { url: "email-read", component: Read },
    { url: "app-calender", component: Calendar },
    { url: "post-details", component: PostDetails },
    { url: "edit-profile", component: UpdateProfile },

    /// Chart
    { url: "chart-sparkline", component: SparklineChart },
    { url: "chart-chartjs", component: ChartJs },
    { url: "chart-chartist", component: Chartist },
    { url: "chart-apexchart", component: ApexChart },
    { url: "chart-rechart", component: RechartJs },

    /// Bootstrap
    { url: "ui-alert", component: UiAlert },
    { url: "ui-badge", component: UiBadge },
    { url: "ui-button", component: UiButton },
    { url: "ui-modal", component: UiModal },
    { url: "ui-button-group", component: UiButtonGroup },
    { url: "ui-accordion", component: UiAccordion },
    { url: "ui-list-group", component: UiListGroup },
    { url: "ui-card", component: UiCards },
    { url: "ui-carousel", component: UiCarousel },
    { url: "ui-dropdown", component: UiDropDown },
    { url: "ui-popover", component: UiPopOver },
    { url: "ui-progressbar", component: UiProgressBar },
    { url: "ui-tab", component: UiTab },
    { url: "ui-pagination", component: UiPagination },
    { url: "ui-typography", component: UiTypography },
    { url: "ui-grid", component: UiGrid },

    /// Plugin
    { url: "uc-select2", component: Select2 },
    //{ url: "uc-nestable", component: Nestable },
    { url: "uc-noui-slider", component: MainNouiSlider },
    { url: "uc-sweetalert", component: MainSweetAlert },
    { url: "uc-toastr", component: Toastr },
    { url: "map-jqvmap", component: JqvMap },
    { url: "uc-lightgallery", component: Lightgallery },

    ///Redux
    { url: "todo", component: Todo },
    //{ url: "redux-form", component: ReduxForm },
    { url: "redux-wizard", component: WizardForm },

    /// Widget
    { url: "widget-basic", component: Widget },

    /// Shop
    { url: "ecom-product-grid", component: ProductGrid },
    { url: "ecom-product-list", component: ProductList },
    { url: "ecom-product-detail", component: ProductDetail },
    { url: "ecom-product-order", component: ProductOrder },
    { url: "ecom-checkout", component: Checkout },
    { url: "ecom-invoice", component: Invoice },
    { url: "ecom-product-detail", component: ProductDetail },
    { url: "ecom-customers", component: Customers },

    /// Form
    { url: "form-element", component: Element },
    { url: "form-wizard", component: Wizard },
    { url: "form-editor-summernote", component: SummerNote },
    { url: "form-pickers", component: Pickers },
    { url: "form-validation-jquery", component: jQueryValidation },

    /// table
    { url: "table-filtering", component: FilteringTable },
    { url: "table-sorting", component: SortingTable },
    { url: "table-datatable-basic", component: DataTable },
    { url: "table-bootstrap-basic", component: BootstrapTable },

    /// pages
    { url: "page-register", component: Registration },
    { url: "page-lock-screen", component: LockScreen },
    { url: "page-login", component: Login },
    { url: "forgot-password", component: ForgotPassword },
    { url: "page-error-400", component: Error400 },
    { url: "page-error-503", component: Error503 },

    // view letter
    { url: "view-letter/:letter_id", component: ViewLetter },
  ];

  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  let pagePath = path.split("-").includes("page");

  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}  ${
          menuToggle ? "menu-toggle" : ""
        }`}
      >
        {!pagePath && <Nav />}

        <div className={`${!pagePath ? "content-body" : ""}`}>
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ minHeight: window.screen.height - 60 }}
          >
            <Suspense fallback={<LoadingSpinner />}>
              <Switch>
                {routes.map((data, i) => (
                  <Route
                    key={i}
                    exact
                    path={`/${data.url}`}
                    component={data.component}
                  />
                ))}

                <Route path="/error-400" component={Error400} />
                <Route path="/error-403" component={Error403} />
                <Route path="/error-404" component={Error404} />
                <Route path="/error-500" component={Error500} />
                <Route path="/error-503" component={Error503} />

                <Route component={Error404} />
              </Switch>
            </Suspense>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      {/* <Setting /> */}
      <ScrollToTop />
    </>
  );
};

export default Markup;
