/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect, useState } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link, Redirect, Route, Switch, useLocation } from "react-router-dom";

import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import "./layout.scss";
import { useSelector } from "react-redux";
import { userPermissions } from "./MenusUserPermissions";
import Error400 from "../../pages/Error400";
import LoadingSpinner from "../../components/Backdrop/LoadingSpinner";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() {}
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = () => {
  // const [auth?.permissions?.roles_detail?.permissions, setUserPermissions] = useState();

  const { iconHover, sidebarposition, headerposition, sidebarLayout } =
    useContext(ThemeContext);

  const location = useLocation();

  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);

    //sidebar icon Heart blast
    var handleheartBlast = document.querySelector(".heart");
    function heartBlast() {
      return handleheartBlast.classList.toggle("heart-blast");
    }
    // handleheartBlast.addEventListener("click", heartBlast);
  }, []);
  //For scroll
  const [hideOnScroll, setHideOnScroll] = useState(true);
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll]
  );
  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  /// Active menu
  let deshBoard = [
      "",
      "dashboard-dark",
      "guest-list",
      "guest-details",
      "concierge-list",
      "room-list",
      "task",
    ],
    userManagement = ["manage-users", "add-user", "manage-roles-permission"],
    opManagement = ["manage-clients", "add-client"],
    procurement = ["manage-procurement", "add-procurement"],
    institution = ["manage-institutions", "add-institution"],
    document_type = ["manage-document-type, add-document-type"],
    drms = [
      "all-documents",
      "manage-documents",
      "approved-documents",
      "rejected-documents",
      "assigned-documents",
      "unassigned-documents",
      "all-assigned-documents",
      "authorize-business",
      "logs",
      "search-document",
    ],
    businessLicensing = [
      "manage-business",
      "business-registration",
      "register-business",
      "register-business-client",
    ],
    hr = [
      "employees",
      "employees-salary",
      "view-leave-application",
      "apply-for-leave",
    ],
    communication = ["chat"],
    allDocuments = ["all-documents"],
    manageDocuments = ["manage-documents"],
    approvedDocuments = ["approved-documents"],
    rejectedDocuments = ["rejected-documents"],
    assignedDocuments = ["assigned-documents"],
    unassignedDocuments = ["unassigned-documents"],
    allAssignedDocuments = ["all-assigned-documents"],
    logs = ["logs"],
    chat = ["chat"];

  const { auth } = useSelector((state) => state.auth);

  // if(Object.keys(auth?.permission?.roles_detail?.permission)?.length >= 1) {
  //   return <LoadingSpinner />;
  // }

  return (
    <div
      className={`dlabnav sidebar ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? hideOnScroll > 120
            ? "fixed"
            : ""
          : ""
      }`}
      style={{ background: "#F7F7F7" }}
    >
      <PerfectScrollbar className="dlabnav-scroll">
        <MM className="metismenu" id="menu">
          {/* {auth?.permissions?.roles_detail?.permissions?.some(
            (item) => item.permissionName === "dashboard"
          ) && ( */}
          <li className={`${deshBoard.includes(path) ? "mm-active" : ""}`}>
            <Link to="/">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 icon"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>

              <span className="nav-text">Dashboard</span>
            </Link>
          </li>
          {/* )} */}
          {auth?.permissions?.roles_detail?.permissions?.some(
            (item) => item.permissionName === "PO_compliance"
          ) && (
            <li className={`${drms.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow" to="#">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 icon secondary"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                  />
                </svg>

                <span className="nav-text text-dark">PO Compliance</span>
              </Link>
              <ul>
                {auth?.permissions?.roles_detail?.permissions?.some(
                  (item) => item.permissionName === "all_documents"
                ) && (
                  <li>
                    <Link
                      className={`${
                        location.pathname === "/all-documents-copy"
                          ? "mm-active active-menu"
                          : ""
                      }`}
                      to="/all-documents-copy"
                    >
                      {" "}
                      All Records
                    </Link>
                  </li>
                )}

                {auth?.permissions?.roles_detail?.permissions?.some(
                  (item) => item.permissionName === "search_document"
                ) && (
                  <li>
                    <Link
                      className={`${
                        location.pathname === "/search-document"
                          ? "mm-active active-menu"
                          : ""
                      }`}
                      to="/search-document"
                    >
                      {" "}
                      Search Records
                    </Link>
                  </li>
                )}
                {auth?.permissions?.roles_detail?.permissions?.some(
                  (item) => item.permissionName === "Compliance Transaction"
                ) && (
                  <li>
                    <Link
                      className={`${
                        location.pathname === "/all-documents"
                          ? "mm-active active-menu"
                          : ""
                      }`}
                      to="/all-documents"
                    >
                      {" "}
                      Compliance Transaction
                    </Link>
                  </li>
                )}
                {auth?.permissions?.roles_detail?.permissions?.some(
                  (item) => item.permissionName === "manage_documents"
                ) && (
                  <li>
                    <Link
                      className={`${
                        location.pathname === "/Manage Documents"
                          ? "mm-active active-menu"
                          : ""
                      }`}
                      to="/manage-documents"
                    >
                      {" "}
                      Manage Documents
                    </Link>
                  </li>
                )}
                {auth?.permissions?.roles_detail?.permissions?.some(
                  (item) =>
                    item.permissionName === "Approved Compliance Transaction"
                ) && (
                  <li>
                    <Link
                      className={`${
                        location.pathname === "/approved-documents"
                          ? "mm-active active-menu"
                          : ""
                      }`}
                      to="/approved-documents"
                    >
                      {" "}
                      Approved Compliance Transaction
                    </Link>
                  </li>
                )}
                {auth?.permissions?.roles_detail?.permissions?.some(
                  (item) =>
                    item.permissionName === "Rejected Compliance Transaction"
                ) && (
                  <li>
                    <Link
                      className={`${
                        location.pathname === "/rejected-documents"
                          ? "mm-active active-menu"
                          : ""
                      }`}
                      to="/rejected-documents"
                    >
                      {" "}
                      Rejected Compliance Transaction
                    </Link>
                  </li>
                )}
                {
                  // auth?.permissions?.roles_detail?.permissions?.some(
                  //   (item) => item.permissionName === "Assigned to me"
                  // ) &&
                  <li>
                    <Link
                      className={`${
                        location.pathname === "/assigned-documents"
                          ? "mm-active active-menu"
                          : ""
                      }`}
                      to="/assigned-documents"
                    >
                      {" "}
                      Assigned to me
                    </Link>
                  </li>
                }
                {
                  // auth?.permissions?.roles_detail?.permissions?.some(
                  //   (item) =>
                  //     item.permissionName === "Unassigned Compliance Transaction"
                  // ) &&
                  <li>
                    <Link
                      className={`${
                        location.pathname === "/unassigned-documents"
                          ? "mm-active active-menu"
                          : ""
                      }`}
                      to="/unassigned-documents"
                    >
                      {" "}
                      Unassigned Compliance Transaction
                    </Link>
                  </li>
                }
                {
                  // auth?.permissions?.roles_detail?.permissions?.some(
                  //   (item) =>
                  //     item.permissionName ===
                  //     "All Assigned Compliance Transaction"
                  // ) &&
                  <li>
                    <Link
                      className={`${
                        location.pathname === "/all-assigned-documents"
                          ? "mm-active active-menu"
                          : ""
                      }`}
                      to="/all-assigned-documents"
                    >
                      {" "}
                      All Assigned Compliance Transaction
                    </Link>
                  </li>
                }

                {auth?.permissions?.roles_detail?.permissions?.some(
                  (item) => item.permissionName === "logs"
                ) && (
                  <li>
                    <Link
                      className={`${
                        location.pathname === "/logs"
                          ? "mm-active active-menu"
                          : ""
                      }`}
                      to="/logs"
                    >
                      {" "}
                      Manage Logs
                    </Link>
                  </li>
                )}
                {/* {auth?.permissions?.roles_detail?.permissions?.some(
                  (item) => item.permissionName === "search-document"
                ) && (
                  <li>
                    <Link
                      className={`${
                        location.pathname === "/search-records"
                          ? "mm-active active-menu"
                          : ""
                      }`}
                      to="/search-records"
                    >
                      {" "}
                      Search Records
                    </Link>
                  </li>
                )} */}
              </ul>
            </li>
          )}
          {auth?.permissions?.roles_detail?.permissions?.some(
            (item) => item.permissionName === "business_Licensing"
          ) && (
            <li
              className={`${
                businessLicensing.includes(path) ? "mm-active" : ""
              }`}
            >
              <Link className="has-arrow" to="#">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 icon secondary"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                  />
                </svg>

                <span className="nav-text text-dark">Business Licensing</span>
              </Link>
              <ul>
                {auth?.permissions?.roles_detail?.permissions?.some(
                  (item) => item.permissionName === "business_registration"
                ) && (
                  <li>
                    <Link
                      className={`${
                        location.pathname === "/business-registration-home"
                          ? "mm-active active-menu"
                          : ""
                      }`}
                      to="/business-registration-home"
                    >
                      {" "}
                      Business Registration
                    </Link>
                  </li>
                )}

                {auth?.permissions?.roles_detail?.permissions?.some(
                  (item) => item.permissionName === "authorize_business"
                ) && (
                  <li>
                    <Link
                      className={`${
                        location.pathname === "/authorize-business"
                          ? "mm-active active-menu"
                          : ""
                      }`}
                      to="/authorize-business"
                    >
                      {" "}
                      Authorize Business Registration
                    </Link>
                  </li>
                )}

                {auth?.permissions?.roles_detail?.permissions?.some(
                  (item) => item.permissionName === "edit_business_request"
                ) && (
                  <li>
                    <Link
                      className={`${
                        location.pathname === "/edit-business-requests"
                          ? "mm-active active-menu"
                          : ""
                      }`}
                      to="/edit-business-requests"
                    >
                      {" "}
                      Edit Business Requests
                    </Link>
                  </li>
                )}
                {auth?.permissions?.roles_detail?.permissions?.some(
                  (item) => item.permissionName === "pendingCertificates"
                ) && (
                  <li>
                    <Link
                      className={`${
                        location.pathname === "/pending-certificates"
                          ? "mm-active active-menu"
                          : ""
                      }`}
                      to="/pending-certificates"
                    >
                      {" "}
                      Pending Certificates
                    </Link>
                  </li>
                )}
                {/* {auth?.permissions?.roles_detail?.permissions?.some(
                  (item) => item.permissionName === "manage_business"
                ) && (
                  <li>
                    <Link
                      className={`${
                        path === "manage-business" ? "mm-active" : ""
                      }`}
                      to="/manage-business"
                    >
                      {" "}
                      Manage Bussiness
                    </Link>
                  </li>
                )} */}
              </ul>
            </li>
          )}
          {auth?.permissions?.roles_detail?.permissions?.some(
            (item) => item.permissionName === "hr"
          ) && (
            <li className={`${hr.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow" to="#">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 icon secondary"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                  />
                </svg>

                <span className="nav-text text-dark">HR</span>
              </Link>
              <ul>
                {auth?.permissions?.roles_detail?.permissions?.some(
                  (item) => item.permissionName === "employees"
                ) && (
                  <li>
                    <Link
                      className={`${
                        location.pathname === "/employees"
                          ? "mm-active active-menu"
                          : ""
                      }`}
                      to="employees"
                    >
                      {" "}
                      Employees
                    </Link>
                  </li>
                )}

                {auth?.permissions?.roles_detail?.permissions?.some(
                  (item) => item.permissionName === "employees_salary"
                ) && (
                  <li>
                    <Link
                      className={`${
                        location.pathname === "/employees-salary"
                          ? "mm- active-menu"
                          : ""
                      }`}
                      to="/employees-salary"
                    >
                      {" "}
                      Employees Salary
                    </Link>
                  </li>
                )}

                {/* {auth?.permissions?.roles_detail?.permissions?.some(
                  (item) => item.permissionName === "view_leave_application"
                ) && (
                  <li>
                    <Link
                      className={`${
                        path === "view-leave-application" ? "mm-active" : ""
                      }`}
                      to="/view-leave-application"
                    >
                      {" "}
                      View Leave Application
                    </Link>
                  </li>
                )} */}

                {auth?.permissions?.roles_detail?.permissions?.some(
                  (item) => item.permissionName === "departments"
                ) && (
                  <li>
                    <Link
                      className={`${
                        path === "manage-business" ? "mm-active" : ""
                      }`}
                      to="#"
                    >
                      {" "}
                      Departments
                    </Link>
                  </li>
                )}

                {auth?.permissions?.roles_detail?.permissions?.some(
                  (item) => item.permissionName === "roles"
                ) && (
                  <li>
                    <Link
                      className={`${
                        path === "manage-business" ? "mm-active" : ""
                      }`}
                      to="#"
                    >
                      {" "}
                      Roles
                    </Link>
                  </li>
                )}

                {auth?.permissions?.roles_detail?.permissions?.some(
                  (item) => item.permissionName === "apply_for_leave"
                ) && (
                  <li>
                    <Link
                      className={`${
                        location.pathname === "/apply-for-leave"
                          ? "mm-active active-menu"
                          : ""
                      }`}
                      to="/apply-for-leave"
                    >
                      {" "}
                      Apply For Leave
                    </Link>
                  </li>
                )}
              </ul>
            </li>
          )}
          {auth?.permissions?.roles_detail?.permissions?.some(
            (item) => item.permissionName === "records"
          ) && (
            <li className={`${hr.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow" to="landing-page">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 icon"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25"
                  />
                </svg>

                <span className="nav-text text-dark">Records</span>
              </Link>
              <ul>
                {/* <li>
                  <Link
                    className={`${path === "chat" ? "mm-active" : ""}`}
                    to="/chat"
                  >
                    {" "}
                    Upload Document
                  </Link>
                </li> */}
                {/* <li>
                  <Link
                    className={`${path === "chat" ? "mm-active" : ""}`}
                    to="/chat"
                  >
                    {" "}
                    View Documents
                  </Link>
                </li> */}
                {/* {auth?.permissions?.roles_detail?.permissions?.some(
                  (item) => item.permissionName === "unassigned_documents"
                ) && (
                  <li>
                    <Link
                      className={`${path === "chat" ? "mm-active" : ""}`}
                      to="/unassigned_documents"
                    >
                      {" "}
                      Unassgined Documents
                    </Link>
                  </li>
                )}

                {auth?.permissions?.roles_detail?.permissions?.some(
                  (item) => item.permissionName === "assigned_documents"
                ) && (
                  <li>
                    <Link
                      className={`${path === "chat" ? "mm-active" : ""}`}
                      to="/assigned_documents"
                    >
                      {" "}
                      Assgined Documents
                    </Link>
                  </li>
                )} */}

                {auth?.permissions?.roles_detail?.permissions?.some(
                  (item) => item.permissionName === "manage_documents"
                ) && (
                  <li>
                    <Link
                      className={`${
                        location.pathname === "/manage-documents"
                          ? "mm-active active-menu"
                          : ""
                      }`}
                      to="/manage-documents"
                    >
                      {" "}
                      Manage Documents
                    </Link>
                  </li>
                )}
                {auth?.permissions?.roles_detail?.permissions?.some(
                  (item) => item.permissionName === "approved_documents"
                ) && (
                  <li>
                    <Link
                      className={`${
                        location.pathname === "/approved-documents"
                          ? "mm-active active-menu"
                          : ""
                      }`}
                      to="/approved-documents"
                    >
                      {" "}
                      Approved Compliance Transaction
                    </Link>
                  </li>
                )}

                {auth?.permissions?.roles_detail?.permissions?.some(
                  (item) => item.permissionName === "rejected_documents"
                ) && (
                  <li>
                    <Link
                      className={`${
                        location.pathname === "/rejected-documents"
                          ? "mm-active active-menu"
                          : ""
                      }`}
                      to="/rejected-documents"
                    >
                      {" "}
                      Rejected Compliance Transaction
                    </Link>
                  </li>
                )}
                {auth?.permissions?.roles_detail?.permissions?.some(
                  (item) => item.permissionName === "Ready For Dispatch"
                ) && (
                  <li>
                    <Link
                      className={`${
                        location.pathname === "/ready-for-dispatch"
                          ? "mm-active active-menu"
                          : ""
                      }`}
                      to="/ready-for-dispatch"
                    >
                      {" "}
                      Ready For Dispatch
                    </Link>
                  </li>
                )}
                {auth?.permissions?.roles_detail?.permissions?.some(
                  (item) => item.permissionName === "Records Management"
                ) && (
                  <li>
                    <Link
                      className={`${
                        location.pathname === "/all-documents-copy"
                          ? "mm-active active-menu"
                          : ""
                      }`}
                      to="/all-documents-copy"
                    >
                      {" "}
                      Records Management
                    </Link>
                  </li>
                )}
              </ul>
            </li>
          )}
          {auth?.permissions?.roles_detail?.permissions?.some(
            (item) => item.permissionName === "communication"
          ) && (
            <li
              className={`${communication.includes(path) ? "mm-active" : ""}`}
            >
              <Link className="has-arrow" to="#">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 icon"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8.25 18.75a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 01-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 00-3.213-9.193 2.056 2.056 0 00-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 00-10.026 0 1.106 1.106 0 00-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12"
                  />
                </svg>

                <span className="nav-text text-dark">Communication / PR</span>
              </Link>

              <ul>
                {auth?.permissions?.roles_detail?.permissions?.some(
                  (item) => item.permissionName === "chat"
                ) && (
                  <li>
                    <Link
                      className={`${
                        location.pathname === "/chat"
                          ? "mm-active active-menu"
                          : ""
                      }`}
                      to="/chat"
                    >
                      {" "}
                      Chat
                    </Link>
                  </li>
                )}
              </ul>
            </li>
          )}
          {auth?.permissions?.roles_detail?.permissions?.some(
            (item) => item.permissionName === "records"
          ) && (
            <li className={`${hr.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow" to="landing-page">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 icon"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25"
                  />
                </svg>

                <span className="nav-text text-dark">Records</span>
              </Link>
              <ul>
                {/* <li>
                  <Link
                    className={`${path === "chat" ? "mm-active" : ""}`}
                    to="/chat"
                  >
                    {" "}
                    Upload Document
                  </Link>
                </li> */}
                {/* <li>
                  <Link
                    className={`${path === "chat" ? "mm-active" : ""}`}
                    to="/chat"
                  >
                    {" "}
                    View Documents
                  </Link>
                </li> */}
                {/* {auth?.permissions?.roles_detail?.permissions?.some(
                  (item) => item.permissionName === "unassigned_documents"
                ) && (
                  <li>
                    <Link
                      className={`${path === "chat" ? "mm-active" : ""}`}
                      to="/unassigned_documents"
                    >
                      {" "}
                      Unassgined Documents
                    </Link>
                  </li>
                )}

                {auth?.permissions?.roles_detail?.permissions?.some(
                  (item) => item.permissionName === "assigned_documents"
                ) && (
                  <li>
                    <Link
                      className={`${path === "chat" ? "mm-active" : ""}`}
                      to="/assigned_documents"
                    >
                      {" "}
                      Assgined Documents
                    </Link>
                  </li>
                )} */}

                {auth?.permissions?.roles_detail?.permissions?.some(
                  (item) => item.permissionName === "manage_documents"
                ) && (
                  <li>
                    <Link
                      className={`${
                        location.pathname === "/manage-documents"
                          ? "mm-active active-menu"
                          : ""
                      }`}
                      to="/manage-documents"
                    >
                      {" "}
                      Manage Documents
                    </Link>
                  </li>
                )}
                {auth?.permissions?.roles_detail?.permissions?.some(
                  (item) => item.permissionName === "approved_documents"
                ) && (
                  <li>
                    <Link
                      className={`${
                        location.pathname === "/approved-documents"
                          ? "mm-active active-menu"
                          : ""
                      }`}
                      to="/approved-documents"
                    >
                      {" "}
                      Approved Compliance Transaction
                    </Link>
                  </li>
                )}

                {auth?.permissions?.roles_detail?.permissions?.some(
                  (item) => item.permissionName === "rejected_documents"
                ) && (
                  <li>
                    <Link
                      className={`${
                        location.pathname === "/rejected-documents"
                          ? "mm-active active-menu"
                          : ""
                      }`}
                      to="/rejected-documents"
                    >
                      {" "}
                      Rejected Compliance Transaction
                    </Link>
                  </li>
                )}
                {auth?.permissions?.roles_detail?.permissions?.some(
                  (item) => item.permissionName === "Ready For Dispatch"
                ) && (
                  <li>
                    <Link
                      className={`${
                        location.pathname === "/ready-for-dispatch"
                          ? "mm-active active-menu"
                          : ""
                      }`}
                      to="/ready-for-dispatch"
                    >
                      {" "}
                      Ready For Dispatch
                    </Link>
                  </li>
                )}
                {auth?.permissions?.roles_detail?.permissions?.some(
                  (item) => item.permissionName === "Records Management"
                ) && (
                  <li>
                    <Link
                      className={`${
                        location.pathname === "/all-documents-copy"
                          ? "mm-active active-menu"
                          : ""
                      }`}
                      to="/all-documents-copy"
                    >
                      {" "}
                      Records Management
                    </Link>
                  </li>
                )}
              </ul>
            </li>
          )}

          {/* ******** Reports *********** */}
          {auth?.permissions?.roles_detail?.permissions?.some(
            (item) => item.permissionName === "businessReports"
          ) && (
            <li
              className={`${communication.includes(path) ? "mm-active" : ""}`}
            >
              <Link className="has-arrow" to="#">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 icon"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8.25 18.75a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 01-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 00-3.213-9.193 2.056 2.056 0 00-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 00-10.026 0 1.106 1.106 0 00-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12"
                  />
                </svg>

                <span className="nav-text text-dark">Reports</span>
              </Link>

              <ul>
                {auth?.permissions?.roles_detail?.permissions?.some(
                  (item) => item.permissionName === "BusinessSubReports"
                ) && (
                  <li>
                    <Link
                      className={`${
                        location.pathname === "/business-reports"
                          ? "mm-active active-menu"
                          : ""
                      }`}
                      to="/business-reports"
                    >
                      {" "}
                      Business Reports
                    </Link>
                  </li>
                )}
              </ul>
            </li>
          )}
          {/* {auth?.permissions?.roles_detail?.permissions?.some(
            (item) => item.permissionName === "inventory"
          ) && (
            <li className={`${hr.includes(path) ? "mm-active" : ""}`}>
              <Link to="/landing-page">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 icon"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8.25 21v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21m0 0h4.5V3.545M12.75 21h7.5V10.75M2.25 21h1.5m18 0h-18M2.25 9l4.5-1.636M18.75 3l-1.5.545m0 6.205l3 1m1.5.5l-1.5-.5M6.75 7.364V3h-3v18m3-13.636l10.5-3.819"
                  />
                </svg>

                <span className="nav-text">Inventory</span>
              </Link>
            </li>
          )}

          {auth?.permissions?.roles_detail?.permissions?.some(
            (item) => item.permissionName === "admin"
          ) && (
            <li className={`${hr.includes(path) ? "mm-active" : ""}`}>
              <Link to="/landing-page">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 icon"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7.5 7.5h-.75A2.25 2.25 0 004.5 9.75v7.5a2.25 2.25 0 002.25 2.25h7.5a2.25 2.25 0 002.25-2.25v-7.5a2.25 2.25 0 00-2.25-2.25h-.75m-6 3.75l3 3m0 0l3-3m-3 3V1.5m6 9h.75a2.25 2.25 0 012.25 2.25v7.5a2.25 2.25 0 01-2.25 2.25h-7.5a2.25 2.25 0 01-2.25-2.25v-.75"
                  />
                </svg>

                <span className="nav-text">Admin</span>
              </Link>
            </li>
          )}

          {auth?.permissions?.roles_detail?.permissions?.some(
            (item) => item.permissionName === "spu"
          ) && (
            <li className={`${hr.includes(path) ? "mm-active" : ""}`}>
              <Link to="/landing-page">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 icon"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7.5 7.5h-.75A2.25 2.25 0 004.5 9.75v7.5a2.25 2.25 0 002.25 2.25h7.5a2.25 2.25 0 002.25-2.25v-7.5a2.25 2.25 0 00-2.25-2.25h-.75m-6 3.75l3 3m0 0l3-3m-3 3V1.5m6 9h.75a2.25 2.25 0 012.25 2.25v7.5a2.25 2.25 0 01-2.25 2.25h-7.5a2.25 2.25 0 01-2.25-2.25v-.75"
                  />
                </svg>

                <span className="nav-text">SPU</span>
              </Link>
            </li>
          )}

          {auth?.permissions?.roles_detail?.permissions?.some(
            (item) => item.permissionName === "finance"
          ) && (
            <li className={`${hr.includes(path) ? "mm-active" : ""}`}>
              <Link to="/landing-page">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 icon"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M14.121 7.629A3 3 0 009.017 9.43c-.023.212-.002.425.028.636l.506 3.541a4.5 4.5 0 01-.43 2.65L9 16.5l1.539-.513a2.25 2.25 0 011.422 0l.655.218a2.25 2.25 0 001.718-.122L15 15.75M8.25 12H12m9 0a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>

                <span className="nav-text">Finance / Audit</span>
              </Link>
            </li>
          )}

          {auth?.permissions?.roles_detail?.permissions?.some(
            (item) => item.permissionName === "IT_support"
          ) && (
            <li className={`${hr.includes(path) ? "mm-active" : ""}`}>
              <Link to="/landing-page">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 icon"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7.5 7.5h-.75A2.25 2.25 0 004.5 9.75v7.5a2.25 2.25 0 002.25 2.25h7.5a2.25 2.25 0 002.25-2.25v-7.5a2.25 2.25 0 00-2.25-2.25h-.75m-6 3.75l3 3m0 0l3-3m-3 3V1.5m6 9h.75a2.25 2.25 0 012.25 2.25v7.5a2.25 2.25 0 01-2.25 2.25h-7.5a2.25 2.25 0 01-2.25-2.25v-.75"
                  />
                </svg>

                <span className="nav-text">IT Support</span>
              </Link>
            </li>
          )}

          {auth?.permissions?.roles_detail?.permissions?.some(
            (item) => item.permissionName === "gppi"
          ) && (
            <li className={`${hr.includes(path) ? "mm-active" : ""}`}>
              <Link to="/landing-page">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 icon"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7.5 7.5h-.75A2.25 2.25 0 004.5 9.75v7.5a2.25 2.25 0 002.25 2.25h7.5a2.25 2.25 0 002.25-2.25v-7.5a2.25 2.25 0 00-2.25-2.25h-.75m-6 3.75l3 3m0 0l3-3m-3 3V1.5m6 9h.75a2.25 2.25 0 012.25 2.25v7.5a2.25 2.25 0 01-2.25 2.25h-7.5a2.25 2.25 0 01-2.25-2.25v-.75"
                  />
                </svg>

                <span className="nav-text">GPPI</span>
              </Link>
            </li>
          )} */}
          {/* <li className={`${hr.includes(path) ? "mm-active" : ""}`}>
            <Link to="#">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 icon"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 01-.825-.242m9.345-8.334a2.126 2.126 0 00-.476-.095 48.64 48.64 0 00-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0011.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155"
                />
              </svg>

              <span className="nav-text">GPPI</span>
            </Link>
          </li> */}
          {auth?.permissions?.roles_detail?.permissions?.some(
            (item) => item.permissionName === "user_management"
          ) && (
            <li
              className={`${userManagement.includes(path) ? "mm-active" : ""}`}
            >
              <Link className="has-arrow" to="#">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 icon secondary"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                  />
                </svg>

                <span className="nav-text text-dark">User Management</span>
              </Link>
              <ul>
                {auth?.permissions?.roles_detail?.permissions?.some(
                  (item) => item.permissionName === "manage_user"
                ) && (
                  <li>
                    <Link
                      className={`${
                        location.pathname === "/manage-users"
                          ? "mm-active active-menu"
                          : ""
                      }`}
                      to="/manage-users"
                    >
                      {" "}
                      Manage Users
                    </Link>
                  </li>
                )}

                {auth?.permissions?.roles_detail?.permissions?.some(
                  (item) => item.permissionName === "add_user"
                ) && (
                  <li>
                    <Link
                      className={
                        location.pathname === "/add-user"
                          ? "mm-active active-menu"
                          : ""
                      }
                      to="/add-user"
                    >
                      {" "}
                      Add User
                    </Link>
                  </li>
                )}

                {/* manage-roles-permission */}
                {/* <li>
                  <Link
                    className={`${path === "manage-roles" ? "mm-active" : ""}`}
                    to="/manage-roles"
                  >
                    {" "}
                    Manage Roles
                  </Link>
                </li> */}

                {auth?.permissions?.roles_detail?.permissions?.some(
                  (item) => item.permissionName === "roles"
                ) && (
                  <li>
                    <Link
                      className={`${
                        location.pathname === "/manage-roles-permission"
                          ? "mm-active active-menu"
                          : ""
                      }`}
                      to="/manage-roles-permission"
                    >
                      {" "}
                      Manage Roles Permission
                    </Link>
                  </li>
                )}

                {/* {auth?.permissions?.roles_detail?.permissions?.some(
                  (item) => item.permissionName === "manage_roles"
                ) && ( */}
                <li>
                  <Link
                    className={`${
                      location.pathname === "/add-roles"
                        ? "mm-active active-menu"
                        : ""
                    }`}
                    to="/add-roles-permission"
                  >
                    {" "}
                    Add Role
                  </Link>
                </li>
                {/* )} */}
              </ul>
            </li>
          )}
          {auth?.permissions?.roles_detail?.permissions?.some(
            (item) => item.permissionName === "client_management"
          ) && (
            <li className={`${opManagement.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow" to="#">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 icon"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z"
                  />
                </svg>

                <span className="nav-text text-dark">PO Management</span>
              </Link>
              <ul>
                {auth?.permissions?.roles_detail?.permissions?.some(
                  (item) => item.permissionName === "manage_clients"
                ) && (
                  <li>
                    <Link
                      className={`${
                        location.pathname === "/manage-clients"
                          ? "mm-active active-menu"
                          : ""
                      }`}
                      to="/manage-clients"
                    >
                      {" "}
                      Manage Clients
                    </Link>
                  </li>
                )}

                {auth?.permissions?.roles_detail?.permissions?.some(
                  (item) => item.permissionName === "add_clients"
                ) && (
                  <li>
                    <Link
                      className={`${
                        location.pathname === "/add-client"
                          ? "mm-active active-menu"
                          : ""
                      }`}
                      to="/add-client"
                    >
                      {" "}
                      Add Client
                    </Link>
                  </li>
                )}
              </ul>
            </li>
          )}
          {auth?.permissions?.roles_detail?.permissions?.some(
            (item) => item.permissionName === "procurement_method"
          ) && (
            <li className={`${procurement.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow" to="#">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 icon"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z"
                  />
                </svg>

                <span className="nav-text text-dark">Procurement Method</span>
              </Link>
              <ul>
                {auth?.permissions?.roles_detail?.permissions?.some(
                  (item) => item.permissionName === "manage_procurement"
                ) && (
                  <li>
                    <Link
                      className={`${
                        location.pathname === "/manage-procurement"
                          ? "mm-active active-menu"
                          : ""
                      }`}
                      to="/manage-procurement"
                    >
                      {" "}
                      Manage Method
                    </Link>
                  </li>
                )}

                {auth?.permissions?.roles_detail?.permissions?.some(
                  (item) => item.permissionName === "add_procurement"
                ) && (
                  <li>
                    <Link
                      className={`${
                        location.pathname === "/add-procurement"
                          ? "mm-active active-menu"
                          : ""
                      }`}
                      to="/add-procurement"
                    >
                      {" "}
                      Add Method
                    </Link>
                  </li>
                )}
              </ul>
            </li>
          )}

          {/* ************************************ Tariff ************************************** */}
          {auth?.permissions?.roles_detail?.permissions?.some(
            (item) => item.permissionName === "procurement_method"
          ) && (
            <li className={`${procurement.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow" to="#">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 icon"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z"
                  />
                </svg>

                <span className="nav-text text-dark">Tariff Management</span>
              </Link>
              <ul>
                {auth?.permissions?.roles_detail?.permissions?.some(
                  (item) => item.permissionName === "manage_procurement"
                ) && (
                  <li>
                    <Link
                      className={`${
                        location.pathname === "//manage-tariffs"
                          ? "mm-active active-menu"
                          : ""
                      }`}
                      to="/manage-tariffs"
                    >
                      {" "}
                      Manage Tariff
                    </Link>
                  </li>
                )}
              </ul>
            </li>
          )}

          {auth?.permissions?.roles_detail?.permissions?.some(
            (item) => item.permissionName === "Instituition"
          ) && (
            <li className={`${institution.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow" to="#">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 icon"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z"
                  />
                </svg>

                <span className="nav-text text-dark">Institutions</span>
              </Link>
              <ul>
                {
                  // auth?.permissions?.roles_detail?.permissions?.some(
                  //   (item) => item.permissionName === "manage_procurement"
                  // ) &&
                  <li>
                    <Link
                      className={`${
                        location.pathname === "/manage-institutions"
                          ? "mm-active active-menu"
                          : ""
                      }`}
                      to="/manage-institutions"
                    >
                      {" "}
                      Manage Institutions
                    </Link>
                  </li>
                }

                {
                  // auth?.permissions?.roles_detail?.permissions?.some(
                  //   (item) => item.permissionName === "add_procurement"
                  // ) &&
                  <li>
                    <Link
                      className={`${
                        location.pathname === "/add-institution"
                          ? "mm-active active-menu"
                          : ""
                      }`}
                      to="/add-institution"
                    >
                      {" "}
                      Add Instituition
                    </Link>
                  </li>
                }
              </ul>
            </li>
          )}
          {/* Document Type */}
          {/* {auth?.permissions?.roles_detail?.permissions?.some(
            (item) => item.permissionName === "Instituition"
          ) &&  */}
          {/* ( */}
          {/* <li className={`${document_type.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow" to="#">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 icon"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z"
                />
              </svg>

              <span className="nav-text text-dark">Document Type</span>
            </Link>
            <ul>
              {
                // auth?.permissions?.roles_detail?.permissions?.some(
                //   (item) => item.permissionName === "manage_procurement"
                // ) &&
                <li>
                  <Link
                    className={`${
                      location.pathname === "/manage-document-type"
                        ? "mm-active active-menu"
                        : ""
                    }`}
                    to="/manage-document-type"
                  >
                    {" "}
                    Manage Document Type
                  </Link>
                </li>
              }

              {
                // auth?.permissions?.roles_detail?.permissions?.some(
                //   (item) => item.permissionName === "add_procurement"
                // ) &&
                <li>
                  <Link
                    className={`${
                      location.pathname === "/add-document-type"
                        ? "mm-active active-menu"
                        : ""
                    }`}
                    to="/add-document-type"
                  >
                    {" "}
                    Add Method
                  </Link>
                </li>
              }
            </ul>
          </li> */}
          {/* )} */}
        </MM>
        {/* <div className="dropdown header-profile2 ">
          <div className="header-info2 text-center">
            <img src={profile} alt="" height="35" />
            <div className="sidebar-info">
              <div>
                <h5 className="font-w500 mb-0">Njillan Fye</h5>
                <span className="fs-12">njilan@eden.com</span>
              </div>
            </div>
            <div>
              <Link to={"#"} className="btn btn-md text-secondary">
                Contact Us
              </Link>
            </div>
          </div>
        </div> */}
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
