import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import LoadingSpinner from "../Backdrop/LoadingSpinner";

const EditBusinessStepFour = ({ handleChange, business }) => {
  const [loading, setLoading] = useState();

  if (!business) {
    return <LoadingSpinner />;
  }

  return (
    <Fragment>
      <form>
        <div className="row">
          <div className="col-xl-12 col-lg-12">
            <div className="card">
              <div className="card-header">
                {/* <h4 className="card-title">
                  Business Information saved Successfully, collect payment to
                  finalize Business Registration
                </h4> */}
              </div>
              <div style={{ padding: 20 }}>
                <h4 style={{ paddingBottom: 20 }}>
                  Summary of Updated Business
                </h4>
                <p>
                  <span className="fw-bold">Name of Business: </span>
                  {business?.nameOfBusiness}
                </p>
                <p>
                  <span className="fw-bold">Address 1: </span>
                  {business?.address1}
                </p>
                <p>
                  <span className="fw-bold">Address 2: </span>
                  {business?.address2}
                </p>
                <p>
                  <span className="fw-bold">City: </span>
                  {business?.city}
                </p>
                <p>
                  <span className="fw-bold">Post Box: </span>
                  {business?.postBox}
                </p>
                <p>
                  <span className="fw-bold">Country: </span>
                  {business?.country}
                </p>
                <p>
                  <span className="fw-bold">Phone Number: </span>
                  {business?.phoneNumber}
                </p>
                <p>
                  <span className="fw-bold">Email: </span>
                  {business?.email}
                </p>
                <p>
                  <span className="fw-bold">Website: </span>
                  {business?.website}
                </p>
                <p>
                  <span className="fw-bold">Full Owned: </span>
                  {business?.fullOwned}
                </p>
                <p>
                  <span className="fw-bold">Parent Company: </span>
                  {business?.parentCompany}
                </p>
                <p>
                  <span className="fw-bold">
                    Subsidiaries and State if partially, full owned:{" "}
                  </span>
                  {business?.subsidiariesAndState}
                </p>
                <p>
                  <span className="fw-bold">Expert Area of Business: </span>
                  {business?.experteAreaOfBusiness}
                </p>
                <p>
                  <span className="fw-bold">Type of Business: </span>
                  {business?.typeOfBusiness}
                </p>
                <p>
                  <span className="fw-bold">Nature of Business: </span>
                  {business?.natureOfBusiness}
                </p>
                <p>
                  <span className="fw-bold">Total Amount: </span>
                  {business?.totalAmount}
                </p>
                {/* <p>
                  <span className="fw-bold">Business Certificate: </span>
                  {business?.businessCertificate?.name}
                </p>
                <p>
                  <span className="fw-bold">Tin Certificate: </span>
                  {business?.tinCertificate?.name}
                </p>
                <p>
                  <span className="fw-bold">ID/Passport: </span>
                  {business?.idCertificate?.name}
                </p> */}
              </div>
              {/* <div className="card-body">
                <div className="basic-form" style={{display: 'flex', justifyContent: 'center'}}>
                  <button className="btn btn-secondary" style={{display: 'flex', justifyContent: 'center'}}>View Information</button>
                </div>
              </div> */}
              {/* <div>
                <button type="button" onClick={submitForm}></button>
              </div> */}
            </div>
          </div>
        </div>
      </form>
    </Fragment>
  );
};

export default EditBusinessStepFour;
