import http from "../http-common";
import {
  handleUserManagementRequest,
  getUserManagementSuccess,
  getUserManagementRoleSuccess,
  getAllRolesSuccess,
  getAllPermissionsSuccess,
  getBusinessClientSucess,
  getBusinessClientIDSuccess,
  getBusinessSearchSuccess,
  getSearchBusinessClientSuccess,
  getAllBusinessSuccess,
  handleUserManagementError,
  getBusinessSearchError,
} from "../redux/Slices/UserManagementSlice";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

export const addUser = (data, dispatch, history) => {
  dispatch(handleUserManagementRequest());
  http
    .post("/auth/register/", data)
    .then((response) => {
      toast.success(response.data.message);
      // toast.success("User added successfully");
      history.push(`/manage-users`);
      //history.push(`/admin/beneficiaries/${data.customer_id}`);
    })
    .catch((error) => {
      toast.error(error.response.data.message);
    });
};

export const getUsers = (dispatch) => {
  dispatch(handleUserManagementRequest());

  http
    .get(`/user/`)
    .then((response) => {
      console.log("Response ", response.data.data);
      dispatch(getUserManagementSuccess(response.data.data));
    })
    .catch((error) => {
      dispatch(handleUserManagementError);
      toast.error(error.response.data.message);
    });
};

export const getUser = async (user_id) => {
  const data = await http
    .get(`/user/${user_id}`)
    .then((response) => {
      return response.data.data;
    })
    .catch((err) => {
      console.log(err);
      return err.response.data;
    });

  return data;
};

export const updateUser = (data, dispatch, history) => {
  dispatch(handleUserManagementRequest());
  http
    .put(`/user/updateUser/`, data)
    .then((response) => {
      toast.success(response.data.message);
      history.push(`/manage-users`);
    })
    .catch((error) => {
      toast.error(error.response.data.message);
    });
};

export const updateUserByAdmin = (data, id, dispatch, history) => {
  dispatch(handleUserManagementRequest());
  console.log("form data:====", data);
  console.log("form data:====", id);

  http
    .put(`/user/updateUserByAdmin/${id}`, data)
    .then((response) => {
      toast.success(response.data.message);
      history.push(`/manage-users`);
    })
    .catch((error) => {
      toast.error(error.response.data.message);
    });
};

export const getBusinessClient = (dispatch) => {
  dispatch(handleUserManagementRequest());

  http
    .get(`/bussinessClient/`)
    .then((response) => {
      console.log("Response ", response.data.data);
      dispatch(getBusinessClientSucess(response.data.data));
    })
    .catch((error) => {
      dispatch(handleUserManagementError);
      toast.error(error.response.data.message);
    });
};

export const getAllBusinesses = (dispatch) => {
  dispatch(handleUserManagementRequest());

  http
    .get(`/bussinessRegistration/businessStatus/`)
    .then((response) => {
      dispatch(getAllBusinessSuccess(response.data.data));
    })
    .catch((error) => {
      dispatch(handleUserManagementError);
      toast.error(error.response.data.message);
    });
};

export const registerBusiness = (data, params, dispatch, history) => {
  // data.forEach((data) => console.log(data));
  console.log(data);
  console.log("params");
  console.log(params);
  dispatch(handleUserManagementRequest());
  http
    .post(`/bussinessRegistration/adminRegisterBussiness/${params.id}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      console.log(data.id);
      console.log("data = ", response);
      toast.success(response.data.message);
    })
    .catch((error) => {
      console.log(error);
      toast.error(error.response.data.message);
    });
};

export const updateBusiness = async (data, params, history) => {
  try {
    const res = await http.put(
      `bussinessRegistration/updateBusinessForAdmin/${params.id}`,
      data
    );
    console.log(res);
    toast.success("business updated successfully");
    history.push(`/view-business/${params.id}`);
  } catch (error) {
    console.log(error.message);
  }

  // http
  //   .put(`bussinessRegistration/updateBusinessForAdmin/${params.id}`, data)
  //   .then((response) => {
  //     console.log(data.id);
  //     toast.success("business updated successfully");
  //   })
  //   .catch((error) => {
  //     console.log(error.message);
  //   });
};

export const registerBusinessClient = (data, dispatch, history) => {
  dispatch(handleUserManagementRequest());
  http
    .post(`/bussinessClient/register/`, data)
    .then((response) => {
      console.log("response");
      console.log(response);
      dispatch(getBusinessClientIDSuccess(response.data.data.id));
      toast.success("registration successs");
      Swal.fire({
        title:
          "Will you like to proceed and create a business for this client?",
        text: "This will direct you to the business wizard",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, update assigned document!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          history.push("/create-business/" + response.data.data.id);
        } else {
          history.push("/business-registration-home");
        }
      });
    })
    .catch((error) => {
      toast.error(error.response.data.message);
    });
};

export const getBusinessClientById = (id) => {
  const data = http
    .get(`/bussinessClient/${id}`)
    .then((response) => {
      console.log("Response", response);
      return response?.data?.data;
    })
    .catch((error) => {
      return error.response;
    });

  return data;
};

export const editBusinessClient = (data, dispatch) => {
  dispatch(handleUserManagementRequest());
  http
    .put(`/bussinessClient/updateBusinessClientProfile/`, data)
    .then((response) => {
      console.log("response");
      console.log(response);
      toast.success(response?.data?.message);
    })
    .catch((error) => {
      const errorMessage = error.response?.data?.message || "An error occurred";
      toast.error(errorMessage);
      console.log("Edit client error", error)
    });
};

export const resetBusinessClientPassword = (data) => {
  http
    .put(`/bussinessClient/resetPassword/`, data)
    .then((response) => {
      console.log("response");
      console.log(response);
      toast.success(response?.data?.message);
    })
    .catch((error) => {
      const errorMessage = error.response?.data?.message || "An error occurred";
      toast.error(errorMessage);
      console.log("Reset client password error", error)
    });
};

export const registerSearchBusiness = (data, dispatch, history) => {
  dispatch(handleUserManagementRequest());
  http
    .get(`/bussinessRegistration/searchBusiness?`, {
      params: {
        ...data,
      },
    })
    .then((response) => {
      if (Object.keys(response.data.data)?.length === 0) {
        toast.error("No data found");
        dispatch(getBusinessSearchError(response.data.message));
        dispatch(handleUserManagementError("No data found"));
      } else {
        dispatch(getBusinessSearchSuccess(response.data.data));
        console.log(response, "Logging response ==== ", response);
      }
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      dispatch(handleUserManagementError(error.response.data.message));
      console.log("Get businesses error", error);
    });
};

export const searchBusinessClientByNumber = (data, dispatch, history) => {
  dispatch(handleUserManagementRequest());
  http
    .post(`/bussinessRegistration/searchBusinessClient/`, data)
    .then((response) => {
      if (Object.keys(response.data.data)?.length === 0) {
        toast.error("No data found");
        dispatch(handleUserManagementError());
      } else {
        dispatch(getSearchBusinessClientSuccess(response.data.data));
        toast.success("search result successs");
      }
    })
    .catch((error) => {
      toast.error(error.response.data.message);
    });
};

export const getUserRoles = (dispatch) => {
  dispatch(handleUserManagementRequest());

  http
    .get(`/user/getRoleUsers/`)
    .then((response) => {
      console.log("Response ", response.data.data);
      dispatch(getUserManagementRoleSuccess(response.data.data));
    })
    .catch((error) => {
      dispatch(handleUserManagementError);
      toast.error(error.response.data.message);
    });
};

export const updateRolePermission = (data) => {
  http
    .post(`/permission/updateRolePermission`, data)
    .then((response) => {
      toast.success(response.data.message);
      console.log("Response ", response);
      window.location.reload();
    })
    .catch((error) => {
      console.log(error);
      toast.error(error.response.data.message);
    });
};

export const getAllRoles = async (dispatch) => {
  dispatch(handleUserManagementRequest());

  const result = await http
    .get(`/role/`)
    .then((response) => {
      dispatch(getAllRolesSuccess(response.data.data));
      return response.data;
    })
    .catch((error) => {
      dispatch(handleUserManagementError());
      toast.error(error.response.data.message);
    });

  return result;
};

export const getAllPermission = (dispatch) => {
  dispatch(handleUserManagementRequest());

  http
    .get(`/permission/`)
    .then((response) => {
      dispatch(getAllPermissionsSuccess(response.data.data));
    })
    .catch((error) => {
      dispatch(handleUserManagementError());
      toast.error("permission error");
    });
};

export const getSinglePermission = async (role_id) => {
  const data = await http
    .get(`/permission/${role_id}`)
    .then((response) => {
      return response.data.permission;
    })
    .catch((err) => {
      console.log(err);
      return err.response.data;
    });

  return data;
};

export const uploadUserProfile = (data, dispatch, history) => {
  dispatch(handleUserManagementRequest);
  http
    .post("/user/upload/", data)
    .then((response) => {
      // toast.success(response.data.message);
      // window.location.reload();
      // history.push(`/users`);
      //history.push(`/admin/beneficiaries/${data.customer_id}`);
    })
    .catch((error) => {
      toast.error(error.message);
    });
};

export const ForgotUserPassword = async (data) => {
  // http
  //   .post("user/forgotUserPassword", data)
  //   .then((response) => {
  //     return( response.data)
  //   })
  //   .catch((error) => {
  //     toast.error(error.message);
  //   });
  try {
    const response = await http.post("user/forgotUserPassword", data);
    if (response) {
      return response.data;
    }
  } catch (error) {
    toast.error(error.data.message);
  }
};

export const ForgotUserPasswordToken = async (token) => {
  try {
    const response = await http.get(`user/verifyUserToken/${token}`);
    return response.data;
  } catch (error) {
    return toast.error(error.response.data.message);
  }
};

export const forgotUserPasswordNewPassword = (payload) => {
  http
    .put("user/updateForgottenUserPassword", payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // toast.error(error.message);
    });
};

export const addRolePermission = async (data, history) => {
  try {
    const response = await http.post("/role", data);
    toast.success(response.data.message);
    history.push(`/manage-roles-permission`);
    return response.data.data;
  } catch (error) {
    toast.error(error.data.message);
    throw error;
  }
};

export const diactivateUser = async (userId) => {
  try {
    const response = await http.put(`/user/activateOrDeactivateUser/${userId}`);
    toast.success(response.data.message);
  } catch (error) {
    console.log(error);
  }
};
