import React, { Fragment, useEffect, useState } from "react";
import { Stepper, Step } from "react-form-stepper";
import { Modal } from "react-bootstrap";
import {
  registerBusinessClient,
  registerBusiness,
  updateBusiness,
  registerSearchBusiness,
} from "../../../services/userManagementService";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../Backdrop/LoadingSpinner";
import Swal from "sweetalert2";
import EditBusinessStepOne from "./EditBusinessStepOne";
import EditBusinessStepTwo from "./EditBusinessStepTwo";
import EditBusinessStepThree from "./EditBusinessStepThree";
import EditBusinessStepFour from "./EditBusinessStepFour";
import {
  getSingleBusiness,
  getSingleBusinessTwo,
  getSingleEditBusiness,
  sendEditRequest,
} from "../../../services/businessService";

export const EditBusiness = () => {
  const { business_id } = useParams();
  const [loading, setLoading] = useState(false);
  const [goSteps, setGoSteps] = useState(0);
  const history = useHistory();
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const [initialBusiness, setInitialBusiness] = useState({});
  const [business, setBusiness] = useState({
    nameOfBusiness: "",
    address1: "",
    address2: "",
    city: "",
    country: "",
    postBox: "",
    name: "",
    title: "",
    email: "",
    website: "",
    phoneNumber: "",
    bussinessClientId: business_id,
    parentCompany: "",
    subsidiariesAndState: "",
    fullOwned: "",
    experteAreaOfBusiness: "",
    gender: "",
    natureOfBusiness: [],
    totalAmount: "",
    typeOfBusiness: "",
    businessCertificate: null,
    tinCertificate: null,
    idCertificate: null,
  });
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    setBusiness({ ...business, [e.target.id]: e.target.value });
  };

  const handleGotoSteps = (index) => {
    setGoSteps(index);
  };

  const handleSubmit = async (e) => {
    setSubmitting(true);
    setLoading(true);

    let formData = new FormData();
    Object.keys(business).forEach((key) => {
      formData.append(key, business[key]);
    });

    try {
      setLoading(true);
      await sendEditRequest(formData, history);
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleConfirmation = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "This action is not revertable",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, send request!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        handleSubmit();
      }
    });
  };

  const fetchSingleBusinessTwo = async () => {
    try {
      setLoading(true);
      const response = await getSingleBusiness(business_id, dispatch);
      setBusiness(response);
      setInitialBusiness(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Fetch single business error", error);
    }
  };

  useEffect(() => {
    fetchSingleBusinessTwo();
  }, []);

  const isBusinessChanged = () => {
    return JSON.stringify(business) !== JSON.stringify(initialBusiness);
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <div className="row">
        <div className="col-xl-12 col-xxl-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Send an Edit Business Request</h4>
            </div>
            <div className="card-body">
              <div className="form-wizard">
                <Stepper
                  className="nav-wizard"
                  activeStep={goSteps}
                  label={false}
                >
                  <Step
                    className="nav-link"
                    onClick={() => handleGotoSteps(0)}
                  />
                  <Step
                    className="nav-link"
                    onClick={() => handleGotoSteps(1)}
                  />
                  <Step
                    className="nav-link"
                    onClick={() => handleGotoSteps(2)}
                  />
                  <Step
                    className="nav-link"
                    onClick={() => handleGotoSteps(3)}
                  />
                </Stepper>
                {goSteps === 0 && (
                  <>
                    <EditBusinessStepOne
                      handleChange={handleChange}
                      formErrors={formErrors}
                      business={business}
                      handleGotoSteps={handleGotoSteps}
                    />
                  </>
                )}
                {goSteps === 1 && (
                  <>
                    <EditBusinessStepTwo
                      handleChange={handleChange}
                      formErrors={formErrors}
                      business={business}
                      setBusiness={setBusiness}
                    />
                    <div className="text-end toolbar toolbar-bottom p-2">
                      <button
                        className="btn btn-secondary sw-btn-prev me-1"
                        onClick={() => handleGotoSteps(0)}
                      >
                        Previous
                      </button>
                      <button
                        className="btn btn-primary sw-btn-prev me-1"
                        onClick={() => handleGotoSteps(2)}
                      >
                        Next
                      </button>
                    </div>
                  </>
                )}
                {goSteps === 2 && (
                  <>
                    <EditBusinessStepThree
                      handleChange={handleChange}
                      business={business}
                      setBusiness={setBusiness}
                      formErrors={formErrors}
                    />
                    <div className="text-end toolbar toolbar-bottom p-2">
                      <button
                        className="btn btn-secondary sw-btn-prev me-1"
                        onClick={() => handleGotoSteps(1)}
                      >
                        Previous
                      </button>
                      <button
                        className="btn btn-primary sw-btn-next ms-1"
                        onClick={() => handleGotoSteps(3)}
                      >
                        Next
                      </button>
                    </div>
                  </>
                )}
                {goSteps === 3 && (
                  <>
                    <EditBusinessStepFour business={business} />
                    <div className="text-end toolbar toolbar-bottom p-2">
                      <button
                        className="btn btn-secondary sw-btn-prev me-1"
                        onClick={() => handleGotoSteps(2)}
                      >
                        Previous
                      </button>
                      <button
                        className="btn btn-primary sw-btn-next ms-1"
                        onClick={handleConfirmation}
                        disabled={!isBusinessChanged() || submitting}
                      >
                        Submit Request
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
